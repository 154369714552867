<template>
  <div class="home">
    <x-header>公式识别</x-header>

    <x-content
      :contentStyle="{
        display: 'block',
        width: 'calc(100% - 32px)',
        padding: '16px',
      }"
    >
      <x-card>
        <el-row :gutter="20" class="latexOcr">
          <el-col :span="12" :offset="currImg ? 0 : 6">
            <div class="previewImg">
              <el-image
                v-if="currImg"
                class="img"
                :src="currImg"
                :preview-src-list="srcList"
              >
              </el-image>
            </div>
            <div class="uploadDiv">
              <el-upload
                accept="image/png, image/jpeg"
                class="upload-demo"
                :before-upload="beforeUpload"
                action=""
                :auto-upload="false"
                :file-list="fileList"
                :on-change="changeFile"
                drag
                :on-success="handleFileUploadSuccess"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将需要识别的文件拖到此处，或 <em>点击上传</em>
                  <div class="el-upload__tip" slot="tip">
                    支持最大10Mb的jpg/png文件
                  </div>
                </div>
              </el-upload>
            </div>
          </el-col>
          <el-col :span="12" v-if="currImg">
            <div class="rightData">
              <h3>识别结果</h3>
              <p
                v-if="latexStr"
                v-html="formattedExpression"
                style="text-align: center"
                contenteditable="true"
              ></p>

              <el-empty v-else description="暂无识别结果"></el-empty>

              <div>
                <el-row style="margin-bottom: 10px">
                  <el-dropdown @command="handleCommand" size="small">
                    <el-button
                      size="mini"
                      @click="copyLatex()"
                      round
                      type="success"
                      plain
                      icon="el-icon-document-copy"
                      >&nbsp;复制LateX<i
                        class="el-icon-arrow-down el-icon--right"
                      ></i
                    ></el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command=""
                        >无特殊附加(复制LaTex)</el-dropdown-item
                      >
                      <el-dropdown-item command="$;$"
                        >$ ... $ 格式(复制LaTex)</el-dropdown-item
                      >
                      <el-dropdown-item command="$$;$$"
                        >$$ ... $$ 格式(复制LaTeX)</el-dropdown-item
                      >
                      <el-dropdown-item command="\[;\]"
                        >\[ ..\]格式(复制LaTeX)</el-dropdown-item
                      >
                      <el-dropdown-item command="\(;\)"
                        >\( .. \)格式(复制LaTex)</el-dropdown-item
                      >
                      <el-dropdown-item
                        command="\begin{eqaution};\end{equation}"
                        >\begin{eqaution} ... \end{equation}
                        格式(复制LaTeX)</el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </el-dropdown>
                  <el-button
                    style="margin-left: 10px"
                    @click="extractMathMLAndCopy()"
                    size="mini"
                    round
                    type="success"
                    plain
                    icon="el-icon-document-copy"
                    >&nbsp;复制MathML</el-button
                  >
                  <el-button
                    style="margin-left: 10px"
                    @click="extractAsciiMathAndCopy()"
                    size="mini"
                    round
                    type="success"
                    plain
                    icon="el-icon-document-copy"
                    >&nbsp;复制AsciiMath</el-button
                  >
                </el-row>

                <el-input
                  type="textarea"
                  v-model="latexStr"
                  :autosize="{ minRows: 8, maxRows: 10 }"
                ></el-input>
              </div>
            </div>
          </el-col>
        </el-row>
      </x-card>
    </x-content>
  </div>
</template>
<script>
import XCard from "../../components/busiess/XCard.vue";
import katex from "katex";
import Mathml2AsciiMath from "mathml2asciimath";

import { LatexOcr } from "../../service/researchTools";
import { Loading } from "element-ui";
export default {
  components: { XCard },
  name: "ApplyUseManager",
  data() {
    return {
      currImg: "",
      fileList: [],
      latexStr: "",
      srcList: [],
    };
  },
  filters: {},
  created() {},
  computed: {
    formattedExpression() {
      try {
        return katex.renderToString(this.latexStr, {
          throwOnError: false,
          output: "mathml",
        });
      } catch (e) {
        console.log(e);
        return this.latexStr;
      }
    },
  },
  mounted() {},
  methods: {
    beforeUpload(file) {
      let acceptList = ["png", "jpg"];
      const isLt5M = file.size / 1024 / 1024 < 5;
      let fileType = file.name.split(".").pop().toLowerCase();
      if (acceptList.indexOf(fileType) === -1) {
        this.$message.error(
          "仅支持上传" + acceptList.join("、") + "格式的文件"
        );
        return false;
      }
      if (!isLt5M) {
        this.$message.error("仅支持上传不超过5M的图片");
        return false;
      }
      return true;
    },
    // 文件状态改变时的钩子
    changeFile(file, fileList) {
      this.fileList = [];
      this.srcList = [];
      fileList.forEach(async (item) => {
        const img = await this.getBase64(item.raw);
        this.currImg = img;
        this.srcList.push(this.currImg);
        let loadingInstance = Loading.service({
          target: document.querySelector(".rightData"), // 你的元素类名或ID
        });
        LatexOcr({ file: file.raw })
          .then((e) => {
            let data = this.APIParse(e);
            if (data) {
              this.latexStr = data;
            }
          })
          .catch((e) => {
            this.$message.error(e.message);
          })
          .finally((e) => {
            this.$nextTick(() => {
              // 以服务的方式调用的 Loading 需要异步关闭
              loadingInstance.close();
            });
          });
      });
    },
    // 图片转Base64
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        let imgBase64 = "";
        reader.readAsDataURL(file);
        reader.onload = () => {
          imgBase64 = reader.result;
        };
        reader.onerror = (error) => reject(error);
        reader.onloadend = () => resolve(imgBase64);
      });
    },
    handleFileUploadSuccess(response, file) {
      this.$message.success("上传成功");
      //this.ruleForm.businessLicense = response.fastdfspath
    },
    copyText(data) {
      const aux = document.createElement("input");
      aux.setAttribute("value", data);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand("copy");
      document.body.removeChild(aux);
      this.$nextTick(() => {
        this.$message.success("复制成功");
      });
    },
    copyLatex() {
      this.copyText(this.latexStr);
    },
    handleCommand(command) {
      const latexstr = this.latexStr;
      if (!command || command == "") {
        this.$message.info("LaTex 复制格式已更改为:'无特殊附加(复制LaTex)");

        this.copyText(latexstr);
      } else {
        let arr = command.split(";"); // 使用逗号作为分隔符
        let str = command.replace(";", " ... ");
        this.$message.info(
          "LaTex 复制格式已更改为:'" + str + "格式(复制LaTex)'"
        );

        this.copyText(arr[0] + latexstr + arr[1]);
      }
    },
    extractMathML() {
      // 尝试获取 MathML
      const mathmlElement = this.$el.querySelector("math");

      if (mathmlElement) {
        // 将 MathML 转换为字符串
        const mathmlString = new XMLSerializer().serializeToString(
          mathmlElement
        );

        this.mathmlContent = mathmlString;

        // 触发事件，传递 MathML
        this.$emit("mathml-extracted", {
          mathml: mathmlString,
          isDisplayMode: this.displayMode,
        });
        return mathmlString;
      }
      return null;
    },
    extractMathMLAndCopy() {
      const mathmlStr = this.extractMathML();
      if (mathmlStr) {
        this.copyText(mathmlStr);
      } else {
        this.$message.error("复制MathML失败");
      }
    },
    extractAsciiMathAndCopy() {
      //先将latex转mathml后再转asciimath
      const mathmlStr = this.extractMathML();
      if (mathmlStr) {
        try {
          const converter = new Mathml2AsciiMath(mathmlStr);
          let asciiMathOutput = converter.convert();
          let asciiMathOutputStr = asciiMathOutput.trim().replace(/\s+/g, "");
          this.copyText(asciiMathOutputStr);
        } catch (e) {
          console.log(e);
          this.$message.error("复制AsciiMath失败");
        }
      } else {
        this.$message.error("复制AsciiMath失败");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.home {
  width: 100%;
  height: 100%;
  overflow: auto;
}
</style>
<style >
/* 引入KaTeX的CSS */
@import "~katex/dist/katex.min.css";
.latexOcr {
  padding: 20px;
}
.latexOcr .base-col-12 {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  max-width: calc(50% - 50px);
  margin: 0px 25px;
}

.latexOcr .uploadDiv {
  min-height: 188px;

  padding: 20px;
  position: relative;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 20px;
  /** 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: #f0f3f7;
    border: 1px solid #c3d0e1;
    */
}
.latexOcr .base-col-offset-6 {
  margin-left: 25% !important;
}
.latexOcr .uploadDiv .base-upload {
  width: 100%;
  display: block;
}

.latexOcr .uploadDiv .el-upload-dragger {
  width: 100% !important;
}
.texOcr .uploadDiv .el-upload__tip {
  color: #409eff !important;
}
.latexOcr .uploadDiv .base-upload {
  display: block;
}

.latexOcr .uploadDiv .base-upload-dragger {
  width: 100% !important;
}

.latexOcr .uploadDiv .el-upload__tip {
  color: #409eff !important;
  color: #2f3a91 !important;
}
.latexOcr .base-upload-dragger .el-icon-upload {
  font-size: 67px;
  color: #c0c4cc;
  margin: 40px 0 16px;
  line-height: 50px;
}

.latexOcr .base-upload-dragger .el-upload__text {
  color: #606266;
  font-size: 14px;
  text-align: center;
}

.latexOcr .base-upload-dragger .el-upload__text em {
  color: #409eff;
  font-style: normal;
}
.latexOcr .previewImg {
  margin: 20px;
}
.latexOcr .previewImg .img {
  display: block;
  margin: 0 auto;

  width: auto;
  max-width: 100%;
}
.latexOcr .previewImg .img img{
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,.12), 0 0 6px rgba(0,0,0,.04);
  padding: 20px;
}
.latexOcr .previewImg .base-image__preview {
  width: auto;
  display: block;
  margin: 0 auto;
  max-width: 100%;
}
.latexOcr .rightData {
  margin: 20px;
}
.latexOcr .rightData h3 {
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  margin-bottom: 20px;
  font-weight: bold;
}
.latexOcr .rightData .base-textarea__inner {
  display: block;
  resize: vertical;
  padding: 5px 15px;
  line-height: 1.5;
  box-sizing: border-box;
  width: 100%;
  font-size: inherit;
  color: #606266;
  background-color: #fff;
  background-image: none;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.latexOcr .rightData .base-textarea.is-rippled:before {
  height: 0px;
}
.latexOcr .rightData .base-textarea.is-rippled:after {
  height: 0px;
  transform: none;
}
</style>